import { useAuth } from 'context/auth.context';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';
import RecoveryPasswordByPhoneStep0 from './RecoveryPasswordByPhoneStep0';
import RecoveryPasswordByPhoneStep1 from './RecoveryPasswordByPhoneStep1';
import RecoveryPasswordByPhoneStep2 from './RecoveryPasswordByPhoneStep2';

const RecoveryPasswordByPhone = () => {
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get('phone_number') || false;

  const { step, setStep, setPhoneNumber } = useAuth();

  useEffect(() => {
    if (phoneNumber) {
      setPhoneNumber(phoneNumber);
      setStep(1);
    }
    return () => {
      setStep(0);
    };
  }, [setStep, phoneNumber, setPhoneNumber]);

  if (step === 1) {
    return <RecoveryPasswordByPhoneStep1 />;
  } else if (step === 2) {
    return <RecoveryPasswordByPhoneStep2 />;
  } else {
    return <RecoveryPasswordByPhoneStep0 />;
  }
};

export default RecoveryPasswordByPhone;
