import { DATE_FORMAT } from 'constants/filter';
import moment from 'moment';
import { roundValue } from './shortcuts';

const highlightedText = (text, value) => {
  const index = text.toLowerCase().indexOf(value.toLowerCase());

  const beforeStr = text.substr(0, index);
  const afterStr = text.substr(index + value.length);

  return (
    <span>
      {beforeStr}
      <span
        className="text-green"
        style={{ textTransform: beforeStr.length === 0 ? 'capitalize' : 'initial' }}
      >
        {value}
      </span>
      {afterStr}
    </span>
  );
};

const wrapSearchResults = (data, search) => {
  return data.reduce((arr, item) => {
    const index = item.title.toLowerCase().indexOf(search.toLowerCase());
    let title = item.title;
    let match = index > -1 || (item.children && isSearchData(item.children, search)) || false;

    if (index > -1) {
      title = highlightedText(item.title, search);
    }

    if (item.children && match) {
      return [
        ...arr,
        {
          title,
          titleClean: item.title,
          key: item.key,
          children: wrapSearchResults(item.children, search),
        },
      ];
    }

    if (match) {
      return [
        ...arr,
        {
          title,
          titleClean: item.title,
          key: item.key,
        },
      ];
    } else {
      return arr;
    }
  }, []);
};

const isSearchData = (data, search) =>
  getFlatList(data).some((item) => {
    return item.match || item.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
  }) || false;

const removeItemByKey = (type, key) => (prev) => ({
  ...prev,
  [type]: [...(prev[type].filter((item) => item.key !== key) || [])],
});

const removeItemByItemKey = (type, key) => (prev) => ({
  ...prev,
  [type]: prev[type].filter((itemKey) => itemKey !== key),
});

const deleteStateKey = (prev, key) => {
  if (!prev || !prev[key]) return prev;

  // eslint-disable-next-line no-unused-vars
  const { [key]: tmp, ...rest } = prev;
  return rest;
};

const changeStateValueByIndex = (prev, index, value) => {
  return [...prev.slice(0, index), value, ...prev.slice(index + 1)];
};

const deleteStateValue = (prev, value) => {
  const index = prev.indexOf(value);

  return [...prev.slice(0, index), ...prev.slice(index + 1)];
};

const changeStateScopeValueByIndex = (prev, index, values, limit = 100) => {
  if (!Array.isArray(values)) {
    values = [values];
  }

  const start = prev.slice(0, index);

  const insert = index + values.length > limit ? values.slice(0, limit - index) : values;

  const finish = index + values.length <= limit ? prev.slice(index + values.length) : [];

  return [...start, ...insert, ...finish];
};

function removeDuplicates(data) {
  let seen = {};
  data.forEach((item) => {
    item.key in seen ? (seen[item.key] += 1) : (seen[item.key] = 0);
  });
  return [...new Set(data.filter((item) => seen[item.key] < 1))];
}

function getFlatList(data) {
  return data.reduce(
    (acc, { children, key, title }) =>
      children ? [...acc, ...getFlatList(children), { key, title }] : [...acc, { key, title }],
    [],
  );
}

function getParentKey(key, tree) {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
}
function formatDate(date, format = 'DD.MM') {
  return moment(date).format(format);
}

function transformDate(date) {
  if (!date) return '';
  const start = moment(date[0], DATE_FORMAT);
  const end = moment(date[1], DATE_FORMAT);
  const showFirstMonth = start.format('MMM') !== end.format('MMM');

  return `${start.format('DD')}&nbsp;${
    showFirstMonth ? start.format('MMM') : ''
  }&nbsp;—&nbsp;${end.format('DD')}&nbsp;${end.format('MMM')}`;
}

const removeKeyFromCategories = (arr, key) => {
  let index = -1;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].key === key) {
      index = i;
      break;
    }
  }

  if (index > -1) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  } else {
    return arr;
  }
};

const getReviewsDeclension = (count) => {
  if (count === '-') return '- отзывов';

  if (!Number.isInteger(+count)) return '';

  const last = `${count}`[`${count}`.length - 1];
  if (+last === 1) {
    return `${count.toLocaleString('ru')} отзыв`;
  } else if (+last === 0 || +last >= 5) {
    return `${count.toLocaleString('ru')} отзывов`;
  } else {
    return `${count.toLocaleString('ru')} отзыва`;
  }
};

const getElInNestedArrays = (arr, id, parents) => {
  if (parents.length) return parents;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].key === id) {
      return arr[i];
    }
    if (arr[i].children) {
      parents = getElInNestedArrays(arr[i].children, id, parents);
    }
  }
  return parents;
};

export const formatValue = (value, suppressRound) => {
  if (!Number.isInteger(+value) && typeof value !== 'number') return value;

  if (suppressRound) return new Intl.NumberFormat('ru-RU').format(+value);

  if (!suppressRound) {
    return roundValue(+value);
  }
};

export const countPercent = (value, total) => {
  return Math.round((value / total) * 100);
};

const getRandom = (min = 0, max = 30) => Math.floor(Math.random() * (max - min) + min);

export {
  changeStateScopeValueByIndex,
  changeStateValueByIndex,
  deleteStateKey,
  deleteStateValue,
  formatDate,
  getElInNestedArrays,
  getFlatList,
  getParentKey,
  getRandom,
  getReviewsDeclension,
  removeDuplicates,
  removeItemByItemKey,
  removeItemByKey,
  removeKeyFromCategories,
  transformDate,
  wrapSearchResults,
};
