import { useCallback, useEffect, useRef, useState } from 'react';

import {
  PAGE_INNER_DELIVERY_TAB,
  PAGE_INNER_PRICES_TAB,
  PAGE_PRODUCT_END_ADV,
  PAGE_PRODUCT_END_CHANGES,
  PAGE_PRODUCT_END_COLORS,
  PAGE_PRODUCT_END_LISTING,
  PAGE_PRODUCT_END_SALES,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_SIMILAR,
  PAGE_PRODUCT_END_SIZES,
  PAGE_PRODUCT_END_STOCKS,
  PAGE_SEO_COMPARE,
} from 'constants';
import { useTable } from 'context/table.context';
import { useUI } from 'context/ui.context';
import { HEADER_HEIGHT } from 'helpers/generateGridConfig';
import { STICKY_HEIGHT_CSS_VAR, useStickyHeight } from 'hooks/useStickyObserver';
import { PAGE_INNER_PRODUCTS_SETTINGS, PAGE_PRODUCT } from '../constants';
import { isProductPage } from '../utils/pages';

const PRODUCT_ROUTES = [
  PAGE_PRODUCT_END_SALES,
  PAGE_PRODUCT_END_LISTING,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_SIZES,
  PAGE_PRODUCT_END_COLORS,
  PAGE_PRODUCT_END_STOCKS,
  PAGE_PRODUCT_END_SIMILAR,
  PAGE_PRODUCT_END_CHANGES,
  PAGE_PRODUCT_END_ADV,
];

const MANY_TABLES_PAGES = [PAGE_SEO_COMPARE];

const useStickyTable = (enabled = true) => {
  const { isMobile } = useUI();
  const { dataReady } = useTable();
  const panelHeight = useStickyHeight();

  const headerElementRef = useRef();
  const pinnedRowsElementRef = useRef();
  const footerElementRef = useRef();
  const scrollElementRef = useRef();
  const bodyElementRef = useRef();
  const statusBarRef = useRef();
  const innerTableRef = useRef();
  const innerTableHeaderRef = useRef();
  const innerTableHeaderNextSiblingRef = useRef();
  const innerTableHeaderWrapperRef = useRef();

  const originalStyles = useRef({
    position: '',
    top: '',
    bottom: '',
    left: '',
    right: '',
    zIndex: '',
    backgroundColor: '',
  });
  const isHeaderSticky = useRef(false);
  const isFooterSticky = useRef(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const pinnedRowsHeight = panelHeight + HEADER_HEIGHT;

  const getSelectors = () => {
    if (isProductPage()) {
      const pathName = window.location.pathname;
      for (let i = 0; i < PRODUCT_ROUTES.length; i++) {
        if (pathName.includes(PRODUCT_ROUTES[i])) {
          return {
            header: `#${PRODUCT_ROUTES[i]} .ag-header`,
            pinned: `#${PRODUCT_ROUTES[i]} .ag-floating-top`,
            footer: `#${PRODUCT_ROUTES[i]} #table-footer`,
            statusBar: `#${PRODUCT_ROUTES[i]} .ag-status-bar`,
            scroll: `#${PRODUCT_ROUTES[i]} .ag-body-horizontal-scroll`,
            body: `#${PRODUCT_ROUTES[i]} .ag-body-viewport`,
            innerTables: `#${PRODUCT_ROUTES[i]} .ag-details-grid`,
          };
        }
      }
      return {
        header: `#main .ag-header`,
        pinned: `#main .ag-floating-top`,
        footer: `#main #table-footer`,
        statusBar: `#main .ag-status-bar`,
        scroll: `#main .ag-body-horizontal-scroll`,
        body: `#main .ag-body-viewport`,
        innerTables: `#main .ag-details-grid`,
      };
    }
    return {
      header: `.ag-header`,
      pinned: `.ag-floating-top`,
      footer: `#table-footer`,
      statusBar: `.ag-status-bar`,
      scroll: `.ag-body-horizontal-scroll`,
      body: `.ag-body-viewport`,
      innerTables: `.ag-details-grid`,
    };
  };
  useEffect(() => {
    if (!dataReady || isMobile || !enabled) return;
    const hasManyTables = MANY_TABLES_PAGES.includes(window.location.pathname);
    const selectors = getSelectors();
    const container = document; //.querySelector('#myGrid_product_search_1111');
    const allHeaders = container.querySelectorAll(selectors.header);
    headerElementRef.current = hasManyTables ? allHeaders[allHeaders.length - 1] : allHeaders[0];
    const pinnedRows = container.querySelectorAll(selectors.pinned);
    pinnedRowsElementRef.current = hasManyTables
      ? pinnedRows[pinnedRows.length - 1]
      : pinnedRows[0];
    // footerElementRef.current = document.querySelector(selectors.footer);
    scrollElementRef.current = container.querySelector(selectors.scroll);
    statusBarRef.current = container.querySelector(selectors.statusBar);
    const allBodies = container.querySelectorAll(selectors.body);
    bodyElementRef.current = hasManyTables ? allBodies[allBodies.length - 1] : allBodies[0];
    const header = headerElementRef.current;

    originalStyles.current.position = header?.style.position;
    originalStyles.current.top = header?.style.top;
    originalStyles.current.zIndex = header?.style.zIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, dataReady, window.location.pathname, enabled]);

  const onScroll = useCallback(() => {
    if (isMobile || !enabled) return;
    const header = headerElementRef.current;
    const pinnedRows = pinnedRowsElementRef.current;
    const footer = footerElementRef.current;
    const body = bodyElementRef.current;
    const scroll = scrollElementRef.current;
    const statusBar = statusBarRef.current;
    const allInnerTables = document.querySelectorAll(getSelectors().innerTables);

    if (!header || !body) return;

    let shouldHeaderStick = false;
    let shouldHeaderUnstick = false;

    let shouldFooterStick = false;
    let shouldFooterUnstick = false;

    let headerHeight = header.getBoundingClientRect().height;
    let pinnedHeight = pinnedRows.getBoundingClientRect().height;
    let headerTop = header.getBoundingClientRect().top;
    let bodyTop = body.getBoundingClientRect().top;
    let bodyBottom = body.getBoundingClientRect().bottom;
    let IT;
    const externalHeaderHeight = headerHeight + (panelHeight || 0);
    for (let i = 0; i < allInnerTables.length; i++) {
      const innerTop = allInnerTables[i].getBoundingClientRect().top;
      const innerHeight = allInnerTables[i].getBoundingClientRect().height;
      if (innerTop <= externalHeaderHeight && innerTop + innerHeight > externalHeaderHeight) {
        IT = allInnerTables[i];
        break;
      }
    }
    if (IT) {
      innerTableRef.current = IT;
    } else {
      if (header && header.nextSibling && header.nextSibling.id === 'stickyInnerHeader') {
        header.nextSibling.remove();

        innerTableHeaderRef.current.style.position = '';
        innerTableHeaderRef.current.style.top = '';
        innerTableHeaderWrapperRef.current.style.paddingTop = '';
        innerTableHeaderWrapperRef.current.insertBefore(
          innerTableHeaderRef.current,
          innerTableHeaderNextSiblingRef.current,
        );
        innerTableHeaderWrapperRef.current = null;
        innerTableHeaderNextSiblingRef.current = null;
        innerTableHeaderRef.current = null;
      }

      innerTableRef.current = null;
    }
    if (innerTableRef.current) {
      const innerHeader = innerTableRef.current.querySelector(getSelectors().header);
      if (header && header.nextSibling && header.nextSibling.id !== 'stickyInnerHeader') {
        innerHeader.parentElement.style.paddingTop = headerHeight + 'px';
        innerTableHeaderWrapperRef.current = innerHeader.parentElement;
        innerTableHeaderNextSiblingRef.current = innerHeader.nextSibling;
        innerTableHeaderRef.current = innerHeader;
        const innerLeft = innerTableRef.current.getBoundingClientRect().left || 0;
        const clone = innerHeader;

        clone.setAttribute('id', 'stickyInnerHeader');
        clone.style.position = 'fixed';
        clone.style.top = `${externalHeaderHeight - 1}px`;
        clone.style.zIndex = '30';
        clone.style.paddingLeft = `${innerLeft}px`;
        header.after(clone);
      }
    }
    const path = window.location.pathname;
    const isProduct = path.startsWith(PAGE_PRODUCT);
    const isProductSearch = isProduct && path.includes(PAGE_PRODUCT_END_SEARCH);
    const isSeo = path.startsWith('/seo');
    const isDelivery = path.includes(PAGE_INNER_DELIVERY_TAB);
    const isInnerPricesTab = path.indexOf(PAGE_INNER_PRICES_TAB) >= 0;
    const isInnerProductsSettings = path.indexOf(PAGE_INNER_PRODUCTS_SETTINGS) >= 0;
    const shouldNoIncludeHeader =
      isSeo || isDelivery || isInnerPricesTab || isInnerProductsSettings;

    if (!isHeaderSticky.current) {
      shouldHeaderStick =
        headerTop !== 0 && headerTop < (isProductSearch ? panelHeight : panelHeight);

      // if (shouldHeaderUnstick) {
      // }

      if (shouldHeaderStick) isHeaderSticky.current = true;
    } else {
      shouldHeaderUnstick = bodyTop - (headerHeight + pinnedHeight) > panelHeight;
      if (shouldHeaderUnstick) isHeaderSticky.current = false;
    }
    if (!isFooterSticky.current) {
      shouldFooterStick = bodyBottom >= windowHeight;
      if (shouldFooterStick) isFooterSticky.current = true;
    } else {
      shouldFooterUnstick = bodyBottom < windowHeight;
      if (shouldFooterUnstick) isFooterSticky.current = false;
    }
    if (shouldHeaderStick) {
      if (isProductSearch) {
        window.scrollTo(
          0,
          window.scrollY - pinnedRowsHeight - headerHeight - (headerHeight === 92 ? 23 : 19),
        );
      } else {
        window.scrollTo(0, window.scrollY - headerHeight);
      }
      header.style.position = 'fixed';
      header.style.top = shouldNoIncludeHeader ? 0 : `calc(var(${STICKY_HEIGHT_CSS_VAR}) - 1px)`;
      header.style.zIndex = '30';

      pinnedRows.style.position = 'fixed';
      pinnedRows.style.top = `${pinnedRowsHeight}px`;
      pinnedRows.style.zIndex = '30';
    }
    if (shouldHeaderUnstick) {
      const original = originalStyles.current;
      header.style.position = ''; //original.position;
      header.style.top = original.top;
      header.style.zIndex = original.zIndex;

      pinnedRows.style.position = ''; //original.position;
      pinnedRows.style.top = original.top;
      pinnedRows.style.zIndex = original.zIndex;
    }

    if (shouldFooterStick) {
      if (footer) {
        footer.style.position = 'fixed';
        footer.style.bottom = '0';
        footer.style.left = '0';
        footer.style.right = '0';
        footer.style.zIndex = '40';
        footer.style.backgroundColor = '#fff';
        footer.style.borderTop = '1px solid rgba(0, 0, 0, 0.06)';
      }
      if (statusBar) {
        statusBar.style.position = 'fixed';
        statusBar.style.left = '0';
        statusBar.style.right = '0';
        statusBar.style.zIndex = '40';
        statusBar.style.backgroundColor = '#fff';
        statusBar.style.borderTop = '1px solid rgba(0, 0, 0, 0.06)';
        statusBar.style.bottom = footer
          ? `${(scroll ? footer.offsetHeight + scroll.offsetHeight : footer.offsetHeight) - 2}px`
          : `${scroll ? scroll.offsetHeight - 2 : '0'}px`;
      }
      if (scroll) {
        scroll.style.position = 'fixed';
        scroll.style.bottom = footer ? footer.offsetHeight : '0';
        scroll.style.left = '0';
        scroll.style.right = '0';
        scroll.style.zIndex = '41';
        scroll.style.maxWidth = scroll.offsetWidth - 30;
      }
    }

    if (shouldFooterUnstick) {
      if (scroll) {
        const original = originalStyles.current;
        scroll.style.position = original.position;
        scroll.style.bottom = original.bottom;
        scroll.style.left = original.left;
        scroll.style.right = original.right;
        scroll.style.zIndex = original.zIndex;
        scroll.style.backgroundColor = original.backgroundColor;
        scroll.style.maxWidth = scroll.offsetWidth + 32;
      }
      if (footer) {
        const original = originalStyles.current;
        footer.style.position = original.position;
        footer.style.bottom = original.bottom;
        footer.style.left = original.left;
        footer.style.right = original.right;
        footer.style.zIndex = original.zIndex;
        footer.style.backgroundColor = original.backgroundColor;
        footer.style.borderTop = 'none';
      }
      if (statusBar) {
        const original = originalStyles.current;
        statusBar.style.position = original.position;
        statusBar.style.bottom = original.bottom;
        statusBar.style.left = original.left;
        statusBar.style.right = original.right;
        statusBar.style.zIndex = original.zIndex;
        statusBar.style.backgroundColor = original.backgroundColor;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, enabled, windowHeight, panelHeight, pinnedRowsHeight, windowWidth]);

  const resize = useCallback(() => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
    onScroll();
  }, [onScroll]);

  useEffect(() => {
    if (enabled) {
      window.addEventListener('resize', resize);
      return () => window.removeEventListener('resize', resize);
    }
  }, [resize, enabled]);

  useEffect(() => {
    if (enabled && dataReady) {
      onScroll();
    }
  }, [dataReady, onScroll, enabled]);

  useEffect(() => {
    if (enabled) {
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [enabled, onScroll]);
};

export default useStickyTable;
