import PropTypes from 'prop-types';
import { Suspense, useCallback, useState } from 'react';

import CrossIcon from 'assets/icons/CrossIcon';
import AddSmthButton from 'components/AddSmthButton';
import ButtonPrimary from 'components/ButtonPrimary';
import LoadingSpin from 'components/LoadingSpin';
import { MobileInterfaceV2 } from 'components/MobileInterface/MobileInterface';
import Modal from 'components/Modal';
import PlatformLogo from 'components/PlatformLogo';
import { F } from 'constants';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUI } from 'context/ui.context';
import { FilterContent } from '../components';

const Title = () => {
  const { filter } = useFilterReducer();
  const platform = filter?.[F.PLATFORM];

  return (
    <span className="flex space-x-2 text-base">
      <span>Управление фильтрами</span>

      {platform && <PlatformLogo platformId={platform} />}
    </span>
  );
};

const ResetAllButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex py-1 items-start leading-none text-green transition-all hover:text-gray-900 focus:text-gray-900"
    >
      <CrossIcon className="w-3 h-3 mr-2" />
      Сбросить все фильтры
    </button>
  );
};
ResetAllButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const FilterModal = ({
  tabs,
  onTabCleanByType,
  onSubmit,
  onCancel,
  additionalContentOnTabLeft,
  onReset,
  showUnderTabs,
  opened = false,
}) => {
  const [showModal, setShowModal] = useState(opened);
  const { isMobile } = useUI();

  const addFilterText = isMobile ? 'Фильтр' : 'Добавить фильтр';

  const toggleFilterModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, [setShowModal]);

  const handleSubmit = () => {
    onSubmit();
    setShowModal(false);
  };

  const handleCancel = () => {
    onCancel();
    setShowModal(false);
  };

  return (
    <Suspense fallback={<LoadingSpin />}>
      <AddSmthButton onClick={toggleFilterModal} label={addFilterText} />

      <Modal
        isOpen={!isMobile && showModal}
        title={<Title />}
        closeModal={handleCancel}
        submitModal={handleSubmit}
        width={760}
        externalFooterContent={onReset && <ResetAllButton onClick={onReset} />}
      >
        <FilterContent
          tabs={tabs}
          onTabCleanByType={onTabCleanByType}
          additionalContentOnTabLeft={additionalContentOnTabLeft}
        />
      </Modal>

      <MobileInterfaceV2
        onPanelClose={handleCancel}
        rootClassName="h-4/5 bottom-0 left-0 absolute"
        isActive={showModal}
        headerText="Добавить фильтр"
        onSubmit={handleSubmit}
      >
        <FilterContent
          tabs={tabs}
          onTabCleanByType={onTabCleanByType}
          showUnderTabs={showUnderTabs}
          additionalContentOnTabLeft={additionalContentOnTabLeft}
        />

        <div className="p-4 flex space-x-3">
          {onReset && (
            <ButtonPrimary
              className="flex-1 py-2 justify-center button-primary__inverted-red"
              onClick={onReset}
              label="Сбросить все"
            />
          )}
          <ButtonPrimary
            className="flex-1 py-2 justify-center"
            onClick={onSubmit}
            label="Применить"
          />
        </div>
      </MobileInterfaceV2>
    </Suspense>
  );
};

FilterModal.propTypes = {
  onOk: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      available: PropTypes.bool,
      panel: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onTabCleanByType: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  additionalContentOnTabLeft: PropTypes.node,
  showUnderTabs: PropTypes.bool,
  opened: PropTypes.bool,
};
