import { TableCellSelectAvgPosition, TableCellSelectVisibility } from 'components/TableCellSelect';
import { CELL_SELECT_AVG_POSITION, CELL_SELECT_VISIBILITY } from 'constants';
import ProductSearchModalButton from 'pages/Product/Search/ModalButton';
import PropTypes from 'prop-types';

const TableNameWithListingTimeCell = (props) => {
  const { value = '', valueFormatted = '', data } = props || {};
  const { id } = data;
  let cellValue = valueFormatted ? valueFormatted : value;
  const isSelectVisibility =
    typeof cellValue === 'string' && cellValue?.indexOf(CELL_SELECT_VISIBILITY) >= 0;
  const isSelectAvgPosition =
    typeof cellValue === 'string' && cellValue?.indexOf(CELL_SELECT_AVG_POSITION) >= 0;

  const isSearchPhraseTotal =
    typeof cellValue === 'string' && cellValue?.indexOf('Поисковые фразы') >= 0;

  if (isSelectVisibility) {
    cellValue = cellValue.replace(CELL_SELECT_VISIBILITY, '');
  }

  if (isSelectAvgPosition) {
    cellValue = cellValue.replace(CELL_SELECT_AVG_POSITION, '');
  }

  return (
    <span>
      <span className="flex justify-between items-center  space-x-1">
        <span
          className="truncate max-w-full overflow-hidden"
          dangerouslySetInnerHTML={{ __html: cellValue }}
        />
        {!isSearchPhraseTotal && !isSelectVisibility && !isSelectAvgPosition && (
          <span className="w-3.5 h-3.5 show-on-hover">
            <ProductSearchModalButton modal={{ title: value, categoryId: id }} />
          </span>
        )}
      </span>
      {isSelectVisibility && <TableCellSelectVisibility />}
      {isSelectAvgPosition && <TableCellSelectAvgPosition />}
    </span>
  );
};

TableNameWithListingTimeCell.propTypes = {
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default TableNameWithListingTimeCell;
