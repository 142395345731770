import AuthorizationForm from 'components/AuthorizationForm';
import { useAuth } from 'context/auth.context';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const initialValues = {
  password: '',
  rePassword: '',
};

const RecoveryPasswordByPhoneStep2 = () => {
  const { recoverPassword, tmpToken, phoneNumber } = useAuth();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    return recoverPassword({
      token: tmpToken,
      phone: phoneNumber,
      password: values.password,
      passwordConfirmation: values.rePassword,
    })
      .then((data) => {
        if (('success' in data && !data.success) || data.status !== 200) {
          setError(data?.status || 'Что пошло не так. Поробуйте позже ещё раз.');
        } else {
          setError(null);
          navigate('/login');
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <AuthorizationForm
      fields={[
        {
          name: 'password',
          label: 'Новый Пароль',
          type: 'password',
          checkStrength: true,
        },
        {
          name: 'rePassword',
          label: 'Повторить',
          type: 'password',
        },
      ]}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitLabel="Сохранить"
      showNavigation={false}
      title="Сохранить пароль"
      superError={error}
      useCaptcha
    />
  );
};

export default RecoveryPasswordByPhoneStep2;
