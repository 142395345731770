const NO_CATEGORY_ID = 136935;

export const getFirstCategoryFromArray = (categories = []) => {
  if (Array.isArray(categories)) {
    if (categories.length > 1 && [0, NO_CATEGORY_ID].includes(categories[0].id)) {
      return categories[1];
    }

    return categories[0];
  }

  return null;
};
