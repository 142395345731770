import { TYPE_ABSOLUTE, TYPE_PERCENT, TYPES } from 'constants/chart';
import {
  CELL_PERIOD_PREFIX,
  CELL_SELECT_AVG_POSITION,
  CELL_SELECT_VISIBILITY,
  COLUMNS,
  COLUMNS_IDS,
  FIELD_IDS,
  TABLE_CELL__CUSTOM_DATA_FIELDS,
  TABLE_TYPE,
} from 'constants/table';
import { AMOUNT_WITH_PERCENT_TABLE_CELL_NAME } from 'helpers/generateGridConfig';
import { dateFormatter } from 'utils/table';

const correctValue = (value) => {
  if (Array.isArray(value)) {
    value = value.reduce((acc, value) => `${acc}${acc && ', '}${value.name || value}`, '');
  } else if (typeof value === 'object' && value !== null) {
    value = value.name || '';
  }

  return value;
};

export const HIERARCHY_KEY = 'orgHierarchy';

const overwriteHierarchyValue = {
  wb: 'Wildberries',
};

const overwriteRowValue = ({ key, value }) => {
  value = correctValue(value);

  switch (key) {
    case COLUMNS.PLATFORM:
      return value === 'wb' ? 'wb.ru' : value;
    default:
      return value;
  }
};

const addHierarchyColumn = ({ row, value, parents }) => {
  if (!value) return;

  value = correctValue(value);

  value = overwriteHierarchyValue[value] || value;

  row[HIERARCHY_KEY] = [...parents, `${value}_${row?.id || row?.key}`];
};

const addAdditionalData = ({ row, additionalData, dataItem }) => {
  Object.keys(additionalData).forEach((key) => {
    const index = additionalData[key]?.labels.indexOf(dataItem[COLUMNS.PERIOD]);

    if (index >= 0) {
      row[key] = additionalData[key]?.chartData?.[index] || null;
    }
  });
};

const addNestedData = ({ row, nested, dataItem }) => {
  const data = dataItem[nested];

  row.children = data.map((item) => {
    Object.keys(item).map((key) => {
      item[key] = correctValue(item[key]);
    });
    return item;
  });
};

const formatPercentage = (sum, current) => {
  if (!sum) return '';

  let percent = (+current / +sum) * 100;

  if (!percent.toFixed) return current;

  return percent;
};

const calculatePercent = ({ categoriesData, field, value }) => {
  const sum = categoriesData?.[0]?.[field];

  if (!sum) return null;

  return formatPercentage(sum, value);
};

const getPercent = ({ dataItem, percentKey, categoriesData, field, value }) => {
  if (percentKey) {
    return dataItem[percentKey] ?? '';
  } else {
    return calculatePercent({ categoriesData, field, value });
  }
};

const addPercentData = ({ columnDefs, categoriesData, dataItem, row }) => {
  columnDefs?.forEach((data) => {
    const cellRender = data?.cellRenderer || null;
    const percentKey = data?.cellRendererParams?.percentKey || null;
    const field = data?.field || null;

    const value = dataItem?.[field] || null;
    if (cellRender === AMOUNT_WITH_PERCENT_TABLE_CELL_NAME) {
      const percent = getPercent({ dataItem, percentKey, field, categoriesData, value });
      const key = `${field}_percent`;
      row[key] = percent;
    }
  });
};

const transformRowData = ({
  data,
  additionalData,
  type,
  hierarchy,
  columnDefs,
  categoriesData,
  parents = [],
  nested,
}) => {
  const dataFiltered = [];

  if (Array.isArray(data)) {
    data?.forEach((dataItem) => {
      const row = {};

      Object.keys(dataItem)?.map((key) => {
        let value = dataItem[key];
        if (value !== null && key !== 'children' && key !== nested) {
          if (FIELD_IDS[key] && value?.id) {
            row[FIELD_IDS[key]] = value?.id || '';
          }

          value = overwriteRowValue({
            key,
            row: dataItem,
            value,
            type,
          });

          row[key] = value;
        }
      });

      if (dataItem[COLUMNS_IDS[type]]) {
        row.id = dataItem[COLUMNS_IDS[type]];
      }

      // add percent if necessary
      addPercentData({
        row,
        dataItem,
        columnDefs,
        categoriesData,
      });

      // add hierarchy column if treeData enabled
      if (hierarchy) {
        addHierarchyColumn({
          row,
          value: dataItem[hierarchy],
          parents,
        });
      }

      if (additionalData && typeof additionalData === 'object') {
        addAdditionalData({
          row,
          additionalData,
          dataItem,
        });
      }

      if (type === TABLE_TYPE.SEO_HINTS_REQUESTS) {
        row.children = dataItem.children.map((el) => {
          return {
            ...el,
            children: [...transformRowData({ data: el.children, type, columnDefs, nested })],
          };
        });
      }
      dataFiltered.push(row);
      // parse children for treeData
      if (hierarchy && dataItem.children) {
        dataFiltered.push(
          ...transformRowData({
            data: dataItem.children,
            additionalData,
            hierarchy,
            parents: row[HIERARCHY_KEY],
            type,
            columnDefs,
            categoriesData,
          }),
        );
      }

      if (nested && dataItem?.[nested]) {
        addNestedData({
          row,
          nested,
          dataItem,
        });
      }
    });
  }

  return dataFiltered;
};

const transformListing = ({ data, mainKey }) => {
  if (!data.chart_dates || !data.table_data) return [];

  const result = [];

  if (!data.table_data.length) {
    const row = {
      [mainKey]: '-',
    };

    data.chart_dates?.forEach((item, i) => {
      row[`${CELL_PERIOD_PREFIX}${i}`] = '-';
    });

    result.push(row);
  } else {
    data.table_data?.forEach((item) => {
      const row = {
        [mainKey]: item?.[mainKey] || '',
      };

      const listing = item?.listing?.slice()?.reverse() || [];

      listing?.forEach((value, i) => {
        row[`${CELL_PERIOD_PREFIX}${i}`] = value || null;
      });

      result.push(row);
    });
  }

  return result;
};

const transformProductGrowth = ({ data }) => {
  return data?.map((item) => ({ ...item, name: item.product }));
};

const transformPeriodRow = ({ data, chartDates, emptyVal = '-', diffData }) => {
  const row = {};

  if (!data.length) {
    chartDates?.forEach((item, i) => {
      row[`${CELL_PERIOD_PREFIX}${i}`] = emptyVal;
    });
  } else {
    data?.forEach((value, i) => {
      row[`${CELL_PERIOD_PREFIX}${i}`] = value || value === 0 ? value : emptyVal;
      if (diffData) {
        row[`${CELL_PERIOD_PREFIX}diff_${i}`] =
          diffData[i] || diffData[i] === 0 ? diffData[i] : emptyVal;
      }
    });
  }

  return row;
};

const transformSearchListing = ({
  data,
  chartDates,
  inputKey,
  outputKey,
  additionalDataKeys,
  isSearch,
}) => {
  const result = [];

  if (!data.length) {
    const row = {
      [outputKey]: '-',
      ...transformPeriodRow({ data: [], chartDates, emptyVal: null }),
    };

    result.push(row);
  } else {
    data?.forEach((item) => {
      const listing = item?.listing?.slice()?.reverse() || [];
      const row = {
        [outputKey]: item[inputKey],
        ...transformPeriodRow({
          data: listing,
          chartDates,
          diffData: isSearch ? item?.listing_diff?.slice()?.reverse() || [] : undefined,
          emptyVal: null,
        }),
      };

      additionalDataKeys &&
        additionalDataKeys.length &&
        additionalDataKeys.forEach((key) => {
          row[key] = +item?.[key] || null;
        });

      if (isSearch) {
        row['cluster'] = item?.cluster?.name;
      }

      result.push(row);
    });
  }

  return result;
};

const transformProductSearchTimes = ({ data }) => {
  const items = data.map((item) => {
    const { time, listing } = item;
    return { time, ...listing };
  });
  // console.log(items, 'items');
  return items;
};
const transformProductSearch = ({ data }) => {
  if (!data.chart_dates) return [];

  const searchResult = transformSearchListing({
    isSearch: true,
    chartDates: data.chart_dates,
    data: data?.table_data || [],
    outputKey: COLUMNS.VERTICAL_HEADER,
    inputKey: COLUMNS.SEARCH_PHRASE,
    additionalDataKeys: [
      COLUMNS.FREQUENCY_WB,
      COLUMNS.AVG_POSITION_BY_KEYWORD,
      COLUMNS.SKU_QTY_,
      'id',
    ],
  });
  const visibilityResult = transformPeriodRow({
    chartDates: data.chart_dates,
    data: data?.[COLUMNS.VISIBILITY]?.slice()?.reverse() || [],
    emptyVal: null,
  });
  const positionResult = transformPeriodRow({
    chartDates: data.chart_dates,
    data: data?.[COLUMNS.AVG_POSITION_BY_DAY]?.slice()?.reverse() || [],
    emptyVal: null,
  });

  const searchPhrasesResult = transformPeriodRow({
    chartDates: data.chart_dates,
    data: [],
    emptyVal: ' ',
  });

  const searchPhrasesCount = data?.table_data?.length || 0;

  return {
    pinnedTopRowData: [
      {
        [COLUMNS.VERTICAL_HEADER]: `<b>Поисковых фраз в топ${CELL_SELECT_VISIBILITY}</b>`,
        ...visibilityResult,
      },
      {
        [COLUMNS.VERTICAL_HEADER]: `<b>Средняя позиция в топ${CELL_SELECT_AVG_POSITION}</b>`,
        ...positionResult,
      },
      {
        [COLUMNS.VERTICAL_HEADER]: `<b>Поисковые фразы (${searchPhrasesCount} шт.)</b>`,
        highlighted: true,
        ...searchPhrasesResult,
      },
    ],
    rowData: searchResult,
  };
};

export const productChangesTypes = {
  price: 'Цена',
  video: 'Наличие видео',
  description_count: 'Слов в описании товара',
  specs_count: 'Заполненных характеристик',
  images_count: 'Кол-во фотографий',
  images_link: 'Добавились новые фотографии',
  360: 'Наличие фото 360',
};

export const transformProductChangesValue = (value) => {
  if (value === undefined) return '-';

  if (typeof value === 'boolean') {
    return value ? 'Да' : 'Нет';
  } else {
    return value;
  }
};

const transformProductChanges = ({ data }) => {
  if (!data || !data.length) return [];

  const result = [];

  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (!item.created_at) continue;

    const { created_at, ...params } = item;

    Object.keys(params)?.forEach((type) => {
      result.push({
        [COLUMNS.PERIOD]: dateFormatter(created_at),
        [COLUMNS.TYPE_OF_CHANGE]: productChangesTypes?.[type] || '-',
        [COLUMNS.IT_WAS]: transformProductChangesValue(params[type]?.old),
        [COLUMNS.BECAME]: transformProductChangesValue(params[type]?.new),
      });
    });
  }

  return result;
};

const transformDynamic = ({ data }) => {
  if (!data?.all_data || !data.chart_dates) return [];

  let { all_data, chart_dates } = data;

  chart_dates = chart_dates?.slice()?.reverse() || [];

  const result = {};

  TYPES.forEach((type) => {
    result[type] = [];
  });

  TYPES.forEach((type) => {
    chart_dates.forEach((i, key) => {
      const label = chart_dates[key];
      for (let j = 0; j < all_data.length; j++) {
        let { data, data_type } = all_data[j];
        data = data?.slice()?.reverse() || [];
        // console.log(data_type, 'data_type');
        const indexType =
          type === TYPE_PERCENT
            ? data_type.lastIndexOf(TYPE_ABSOLUTE) === -1
              ? data_type.lastIndexOf(type)
              : -1
            : data_type.lastIndexOf(type);

        if (indexType < 0) continue;

        const categoryName = data_type.slice(0, indexType - 1);
        // console.log(categoryName, 'categoryName');
        result[type][key] = {
          ...result[type][key],
          name: label,
          [categoryName]: data[key],
        };
      }
    });
  });
  return result;
};

const transformPriceSegment = ({ data }) => {
  if (!data || !data.range) return [];

  const { range, ...rest } = data;

  const result = [];

  Object.keys(range).forEach((i, key) => {
    const label = range[key];
    Object.keys(rest).forEach((j) => {
      if (!result[key]) {
        result[key] = {};
      }
      result[key] = {
        ...result[key],
        name: label + ' руб.',
        [j]: Array.isArray(rest[j]) ? rest[j][key] : rest[j],
      };
    });
  });

  return result;
};

const correctDate = ({ data, key }) => {
  return data.map((row) => ({
    ...row,
    ...(row?.[key] && {
      [key]: dateFormatter(row?.[key]),
    }),
  }));
};

const transformForColors = ({ data, columnDefs }) => {
  if (!data || !columnDefs || !columnDefs.length) return [];

  const keys = columnDefs.map((col) => col?.field);
  const keysWithPercent = columnDefs
    .filter((col) => col?.cellRendererParams?.percentKey)
    .reduce((cur, col) => {
      return Object.assign(cur, { [col?.field]: col?.cellRendererParams?.percentKey });
    }, {});

  return Object.keys(data)
    .filter((id) => {
      return typeof data[id].colors !== 'undefined';
    })
    .map((id, idx) => {
      const result = [];

      keys.forEach((field) => {
        result[field] = data?.[id]?.[field] ?? '';

        if (keysWithPercent[field]) {
          result[`${field}_percent`] = data?.[keysWithPercent[field]]?.[id] ?? '';
        }
      });

      return {
        ...result,
        [TABLE_CELL__CUSTOM_DATA_FIELDS.TITLE]: data[id]?.colors?.join(', ') || '',
        [TABLE_CELL__CUSTOM_DATA_FIELDS.INDEX]: idx,
        [TABLE_CELL__CUSTOM_DATA_FIELDS.ID]: id,
      };
    });
};

// const transformForProductWarehousesSizesTabs = ({ data, columnDefs, firstKey }) => {
//   if (!data[firstKey] || !columnDefs || !columnDefs.length) return [];
//
//   const keys = columnDefs.map((col) => col?.field);
//   const keysWithPercent = columnDefs
//     .filter((col) => col?.cellRendererParams?.percentKey)
//     .reduce((cur, col) => {
//       return Object.assign(cur, { [col?.field]: col?.cellRendererParams?.percentKey });
//     }, {});
//
//   return Object.keys(data[firstKey]).map((id, idx) => {
//     const result = [];
//
//     keys.forEach((field) => {
//       result[field] = data?.[field]?.[id] ?? '';
//
//       if (keysWithPercent[field]) {
//         result[`${field}_percent`] = data?.[keysWithPercent[field]]?.[id] ?? '';
//       }
//     });
//
//     return {
//       ...result,
//       [TABLE_CELL__CUSTOM_DATA_FIELDS.TITLE]: id,
//       [TABLE_CELL__CUSTOM_DATA_FIELDS.INDEX]: idx,
//     };
//   });
// };

// const transformForWarehouses = ({ data, columnDefs }) => {
//   return transformForProductWarehousesSizesTabs({
//     data,
//     columnDefs,
//     firstKey: COLUMNS.WAREHOUSES__ORDERS_RUB,
//   });
// };

// const transformForSizes = ({ data, columnDefs }) => {
//   return transformForProductWarehousesSizesTabs({
//     data,
//     columnDefs,
//     firstKey: COLUMNS.ORDERS_RUB_BY_SIZES,
//   });
// };

export const generateGridRowData = ({
  data,
  type,
  additionalData,
  columnDefs,
  categoriesData,
  nested,
}) => {
  let result = {
    rowData: [],
    pinnedTopRowData: [],
  };
  if (Array.isArray(data[0])) {
    return result;
  }
  if (type === TABLE_TYPE.PRODUCT_COLORS) {
    result.rowData = transformForColors({ data, columnDefs });
  } else if (type === TABLE_TYPE.PRODUCT_LISTING) {
    result.rowData = transformListing({ data, mainKey: COLUMNS.CATEGORY });
  } else if (type === TABLE_TYPE.PRODUCT_SEARCH) {
    result = transformProductSearch({ data, mainKey: COLUMNS.VERTICAL_HEADER });
  } else if (type === TABLE_TYPE.PRODUCT_SEARCH_TIMES) {
    result.rowData = transformProductSearchTimes({ data });
  } else if (type === TABLE_TYPE.PRODUCT_CHANGES) {
    result.rowData = transformProductChanges({ data });
  } else if (type === TABLE_TYPE.DYNAMIC) {
    result.rowData = transformDynamic({ data });
  } else if (type === TABLE_TYPE.SALES_BY_DAY) {
    result.rowData = transformDynamic({ data });
  } else if (type === TABLE_TYPE.PRODUCTS_GROWTH) {
    result.rowData = transformProductGrowth({ data });
  } else if (type === TABLE_TYPE.PRICE_SEGMENT) {
    result.rowData = transformPriceSegment({ data });
  } else {
    if (
      type === TABLE_TYPE.PRODUCT_SALES ||
      type === TABLE_TYPE.PRODUCT_ADVERTISING ||
      type === TABLE_TYPE.SALES_BY_DAY
    ) {
      data = data?.slice()?.reverse() || [];
    }

    if (type === TABLE_TYPE.PRODUCT_ADVERTISING) {
      data = correctDate({ data, key: COLUMNS.DATE });
    }
    if (type === TABLE_TYPE.SEO_HINTS_REQUESTS) {
      result.rowData = data;
      return result;
    }
    result.rowData = transformRowData({
      data,
      additionalData,
      type,
      columnDefs,
      categoriesData,
      hierarchy:
        type === TABLE_TYPE.CATEGORIES || type === TABLE_TYPE.CATEGORIES_GROWTH ? 'name' : null,
      nested,
    });
  }

  return result;
};
