import FormInput from 'components/Input/FormInput';
import PropTypes from 'prop-types';

const ProfileEditName = ({ onChange, values }) => {
  return (
    <FormInput
      label="Имя"
      name={'name'}
      value={values?.name}
      onChange={(e) => onChange({ name: e.target.value })}
    />
  );
};

ProfileEditName.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.object,
};

export default ProfileEditName;
