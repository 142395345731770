import FormInput from 'components/Input/FormInput';
import PropTypes from 'prop-types';

const ProfileEditEmail = ({ onChange, values }) => {
  return (
    <FormInput
      label="Email"
      name={'email'}
      value={values?.email}
      onChange={(e) => onChange({ email: e.target.value })}
    />
  );
};

ProfileEditEmail.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.object,
};

export default ProfileEditEmail;
