import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import CheckIcon from 'assets/icons/CheckIcon';
import { CopyIconWithClipboard } from 'assets/icons/CopyIcon';
import DownIcon from 'assets/icons/DownIcon';
import EditIcon from 'assets/icons/EditIcon';
import QuestionIcon from 'assets/icons/QuestionIcon';
import { PopoverV2 } from 'components/PopoverV2';
import { Skeleton } from 'components/Skeleton';
import { roundValue } from 'helpers/shortcuts';
import useClickOutside from 'hooks/useClickOutside';
import { PlusIcon } from '../../assets/icons/PlusIcon';
import InfoDropdown from './InfoDropdown';

const getPostfix = (type, value) => {
  if (value === '-') {
    return '';
  }
  switch (type) {
    case 'currency': {
      return '&nbsp;₽';
    }
    case 'amount': {
      return '&nbsp;шт.';
    }
    case 'percent': {
      return '%';
    }
    default: {
      return '';
    }
  }
};

const SELECT_MODES = {
  CREATE: 'create',
  UPDATE: 'update',
};

const Info = ({
  shortcut,
  isQuestion,
  isQuestionToRight,
  questionText,
  label,
  value,
  percentValue,
  totalValue,
  type,
  isLoading,
  highlighted = false,
  hovered,
  isEditable,
  isSelectable,
  isCopyable,
  getDateInFormat,
  isAllActionsAvailable,
  onUpdate,
  options,
  onCreateOption,
  onUpdateOption,
  onDeleteOption,
  externalLabel,
}) => {
  let valueShortcut = value;
  let valueFull = value;
  const [editValue, setEditValue] = useState(value);
  const [editOptionId, setEditOptionId] = useState(value);
  if (type === 'date') {
    const formattedValue = getDateInFormat ? getDateInFormat(value) : value;
    valueShortcut = formattedValue;
    valueFull = formattedValue;
  } else if (type === 'boolean') {
    valueShortcut = value ? 'Дa' : 'Нет';
    valueFull = valueShortcut;
  } else if (typeof value === 'number' && shortcut) {
    valueShortcut = roundValue(+value, false);
    valueFull = value?.toLocaleString('ru') || '';
  } else if (['currency', 'amount', 'number'].includes(type)) {
    valueShortcut = roundValue(+value, false);
    valueFull = value?.toLocaleString('ru') || '';
  }

  if (totalValue !== undefined) {
    valueShortcut = valueShortcut + ` из ${totalValue}`;
    valueFull = valueFull + ` из ${totalValue}`;
  }

  const [isEditActive, setIsEditActive] = useState(false);
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const [selectMode, setSelectMode] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const postfix = getPostfix(type, shortcut && hovered ? valueFull : valueShortcut);
  const ref = useRef();
  const editRef = useRef();
  useClickOutside(ref, () => setIsDropdownExpanded(false));
  useClickOutside(editRef, () => setIsEditActive(false));
  const handleChangeSubmit = (value) => {
    if (isSelectable) {
      if (selectMode === SELECT_MODES.CREATE) {
        onCreateOption && onCreateOption(editValue);
      } else {
        onUpdateOption && onUpdateOption(editOptionId, value);
      }
      setIsEditActive(false);
    } else {
      handleSetSetting(value);
    }
  };

  const handleSetSetting = (value) => {
    onUpdate && onUpdate(value);
    setIsEditActive(false);
  };
  const handleAddClick = () => {
    setIsDropdownExpanded(false);
    setIsEditActive(true);
    setEditValue('');
    setSelectMode(SELECT_MODES.CREATE);
  };

  const handleEditIconClick = (id, name) => {
    onUpdate && onUpdate({ id, name });
    setIsDropdownExpanded(false);
    setIsEditActive(true);
    setEditValue(name);
    setSelectMode(SELECT_MODES.UPDATE);
    setEditOptionId(id);
  };

  const handleDeleteIconClick = (id) => {
    setIsDropdownExpanded(false);
    onDeleteOption && onDeleteOption(id);
  };
  return (
    <div
      className={cn(`flex flex-nowrap items-center justify-between leading-none relative z-0`, {
        ['mt-1.5']: highlighted,
        ['!z-10']: isDropdownExpanded || isHovered,
      })}
    >
      <span className={'text-gray-400 flex items-center leading-4 whitespace-nowrap'}>
        <span
          className={cn({
            ['bg-green-lightest px-2 py-1 text-green rounded-lg leading-none']: highlighted,
          })}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        {isQuestion && questionText && (
          <div className="group">
            <span
              className="flex p-1 -m-1 ml-1 cursor-pointer"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <QuestionIcon className="h-4 w-4 text-gray-300" />
            </span>
            {isHovered && (
              <PopoverV2
                style={{
                  width: questionText.length < 150 ? `${questionText.length * 7.2 + 24}px` : '',
                }}
                //group-hover:opacity-100 group-hover:!z-10000
                classes={cn('opacity-0 !-z-10000 transition-opacity top-6 ', {
                  ['max-w-[350px]']: questionText.length < 150,
                  ['w-[35rem]']: questionText.length >= 150,
                  ['-left-1']: !isQuestionToRight && questionText.length < 150,
                  ['-left-32']: !isQuestionToRight && questionText.length >= 150,
                  ['-top-20 -right-20']: isQuestionToRight,
                  ['!opacity-100 !z-10000']: isHovered,
                })}
              >
                <div>
                  <span className="font-SofiaPro text-sm font-medium max-w-full !whitespace-normal">
                    {questionText}
                  </span>
                </div>
              </PopoverV2>
            )}
          </div>
        )}

        {externalLabel && (
          <span
            className={cn({
              ['bg-green-lightest px-2 py-1 text-green rounded-lg leading-none']: highlighted,
            })}
            dangerouslySetInnerHTML={{ __html: externalLabel }}
          />
        )}
      </span>

      {!isLoading && typeof percentValue !== 'undefined' && (
        <span className="text-green ml-1">&middot; {percentValue}%</span>
      )}
      <span
        className={`block border-dashed border-gray-250 border-b flex-1 mx-2 self-end mb-[3px]`}
      />

      <Skeleton classNames={`w-full h-3 ${isLoading ? 'visible' : 'hidden'}`} />
      {isEditActive ? (
        <div
          className="flex w-24 border border-gray-250 rounded px-1 py-[3px] bg-white absolute right-0"
          ref={editRef}
        >
          <div className="grow flex mr-2">
            <input
              className="w-full self-center focus:outline-none text-right"
              autoFocus
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleChangeSubmit(editValue);
                }
              }}
            />
          </div>
          <button
            className="bg-green rounded text-white p-1 relative justify-end focus:outline-none"
            onClick={() => handleChangeSubmit(editValue)}
          >
            <CheckIcon width={14} height={14} className="text-right" />
          </button>
        </div>
      ) : (
        <div
          ref={ref}
          className={cn('flex', {
            'hover:text-green cursor-pointer focus:outline-none': isSelectable,
          })}
          onClick={() => {
            if (isSelectable) {
              if (value === '-' && options.length === 0) {
                handleAddClick();
              } else {
                setIsDropdownExpanded(!isDropdownExpanded);
              }
            }
          }}
        >
          <div className={cn('relative')}>
            <span
              className={cn('text-sm leading-none relative whitespace-nowrap', {
                ['text-white']: shortcut && hovered,
                ['hidden']: isLoading,
              })}
              dangerouslySetInnerHTML={{
                __html: isSelectable
                  ? value?.name
                  : shortcut
                    ? `${valueShortcut}${postfix}`
                    : `${valueFull}${postfix}`,
              }}
            />
            {shortcut && hovered && (
              <span
                className={cn(
                  'text-sm leading-none absolute right-0 bg-white-gradient pl-8 whitespace-nowrap',
                  {
                    ['hidden']: isLoading,
                  },
                )}
                dangerouslySetInnerHTML={{ __html: `${valueFull}${postfix}` }}
              />
            )}
          </div>
          {isEditable && (
            <button
              className="ml-2 cursor-pointer hover:text-green focus:outline-none"
              onClick={() => {
                setIsEditActive(true);
                setEditValue(value === '-' ? '' : value);
              }}
            >
              <EditIcon height={12} width={12} />
            </button>
          )}

          {isCopyable && (
            <span className={'text-green'}>
              <CopyIconWithClipboard textToCopy={value} className={'h-4 w-4 ml-1 cursor-pointer'} />
            </span>
          )}
          {isSelectable &&
            (value === '-' ? (
              options.length === 0 ? (
                <div className="text-green flex">
                  <span className="block">Добавить</span>
                  <PlusIcon width={12} height={12} className="ml-1.5 self-center" />
                </div>
              ) : (
                <div className="text-green flex">
                  <span className="block">Выбрать</span>
                  <DownIcon
                    width={14}
                    height={14}
                    className="ml-1.5 self-center relative top-[1px]"
                  />
                </div>
              )
            ) : (
              <div className="ml-2 flex">
                <DownIcon
                  height={12}
                  width={12}
                  className={cn('self-center', {
                    'rotate-180': isDropdownExpanded,
                  })}
                />
              </div>
            ))}
          <InfoDropdown
            isAllActionsAvailable={isAllActionsAvailable}
            options={options}
            isDropdownExpanded={isDropdownExpanded}
            setIsExpanded={setIsDropdownExpanded}
            onSelect={handleSetSetting}
            handleAddClick={handleAddClick}
            handleEditIconClick={handleEditIconClick}
            handleDeleteIconClick={handleDeleteIconClick}
            value={value}
          />
        </div>
      )}
    </div>
  );
};
export const infoPropTypes = {
  value: PropTypes.any,
  percentValue: PropTypes.any,
  totalValue: PropTypes.any,
  label: PropTypes.string.isRequired,
  externalLabel: PropTypes.string,
  shortcut: PropTypes.bool,
  hovered: PropTypes.bool,
  type: PropTypes.oneOf(['currency', 'amount', 'percent', 'boolean', 'date', 'number', '']),
  isLoading: PropTypes.bool,
  highlighted: PropTypes.bool,
  isQuestion: PropTypes.bool,
  isQuestionToRight: PropTypes.bool,
  questionText: PropTypes.any,
  isEditable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isCopyable: PropTypes.bool,
  getDateInFormat: PropTypes.func,
  isAllActionsAvailable: PropTypes.bool,
  options: PropTypes.any,
  onUpdate: PropTypes.func,
  onCreateOption: PropTypes.func,
  onUpdateOption: PropTypes.func,
  onDeleteOption: PropTypes.func,
  linkTo: PropTypes.string,
};

Info.propTypes = infoPropTypes;

export default Info;
