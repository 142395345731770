import FormInput from 'components/Input/FormInput';
import PropTypes from 'prop-types';

const ProfileEditEmailConfirm = ({ onChange }) => {
  return (
    <FormInput label="Код" name={'code'} onChange={(e) => onChange({ code: e.target.value })} />
  );
};

ProfileEditEmailConfirm.propTypes = {
  onChange: PropTypes.func,
};

export default ProfileEditEmailConfirm;
