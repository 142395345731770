export const PAGE_SIGNUP = '/signup';
export const PAGE_RECOVERY_PASSWORD = '/recovery-password';
export const PAGE_MAIN = '/main';
export const PAGE_BRANDS = `${PAGE_MAIN}/brands`;
export const PAGE_PRODUCTS_END = '/products';
export const PAGE_PRODUCTS = `${PAGE_MAIN}${PAGE_PRODUCTS_END}`;
export const PAGE_SALES = `${PAGE_MAIN}/sales`;
export const PAGE_SELLERS = `${PAGE_MAIN}/sellers`;
export const PAGE_SUBJECTS = `${PAGE_MAIN}/subjects`;
export const PAGE_WAREHOUSES = `${PAGE_MAIN}/warehouses`;
export const PAGE_TRENDS = `${PAGE_MAIN}/dynamic`;
export const PAGE_PRICE_SEGMENTS = `${PAGE_MAIN}/price-segments`;
export const PAGE_COMPARISON = `${PAGE_MAIN}/comparison`;

export const PAGE_PRODUCT_END_SALES = 'sales';
export const PAGE_PRODUCT_END_LISTING = 'listing';
export const PAGE_PRODUCT_END_SEARCH = 'search';
export const PAGE_PRODUCT_END_SIZES = 'sizes';
export const PAGE_PRODUCT_END_COLORS = 'colors';
export const PAGE_PRODUCT_END_STOCKS = 'stocks';
export const PAGE_PRODUCT_END_WAREHOUSES = 'warehouses';
export const PAGE_PRODUCT_END_SIMILAR = 'similar';
export const PAGE_PRODUCT_END_CHANGES = 'changes';
export const PAGE_PRODUCT_END_ADV = 'adv';
export const PAGE_PRODUCT_END_ORDERS_SALES = 'orders_sales';

export const PAGE_TARIFFS = '/tariffs';

export const PAGE_POSITIONS = '/positions';

export const PAGE_PRODUCT = '/product';
export const PAGE_PRODUCT_ID = `${PAGE_PRODUCT}/:id`;
export const PAGE_PRODUCT_SALES = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_SALES}`;
export const PAGE_PRODUCT_LISTING = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_LISTING}`;
export const PAGE_PRODUCT_SEARCH = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_SEARCH}`;
export const PAGE_PRODUCT_SIZES = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_SIZES}`;
export const PAGE_PRODUCT_COLORS = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_COLORS}`;
export const PAGE_PRODUCT_STOCKS = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_STOCKS}`;
export const PAGE_PRODUCT_SIMILAR = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_SIMILAR}`;
export const PAGE_PRODUCT_CHANGES = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_CHANGES}`;
export const PAGE_PRODUCT_ADV = `${PAGE_PRODUCT_ID}/${PAGE_PRODUCT_END_ADV}`;

export const PAGE_PROFILE = '/profile';
export const PAGE_PROFILE_SUBSCRIPTIONS = `${PAGE_PROFILE}/subscriptions`;
export const PAGE_PROFILE_REFERRALS = `${PAGE_PROFILE}/referrals`;
export const PAGE_PROFILE_TARIFFS = `${PAGE_PROFILE}/tariffs`;
export const PAGE_SUCCESS_PAYMENT = '/success-payment';
export const PAGE_SUCCESS_PAYMENT_UL = '/confirmation_ul';

export const PAGE_ADVERTISING_RATES = '/promotion-position';

export const PAGE_SEO = '/seo';

export const PAGE_GROWTH = '/growth';
export const PAGE_GROWTH_SELLERS = `${PAGE_GROWTH}/sellers`;
export const PAGE_GROWTH_BRANDS = `${PAGE_GROWTH}/brands`;
export const PAGE_GROWTH_PRODUCTS = `${PAGE_GROWTH}/products`;

export const PAGE_INNER = '/inner';
export const PAGE_INNER_PRICES_TAB = `${PAGE_INNER}/prices`;
export const PAGE_INNER_PRODUCTS_SETTINGS = `${PAGE_INNER}/products-settings`;
export const PAGE_INNER_DELIVERY_TAB = `${PAGE_INNER}/delivery`;
export const PAGE_INNER_PRODUCTS = `${PAGE_INNER}${PAGE_PRODUCTS_END}`;
export const PAGE_INNER_IN_DAYS = `${PAGE_INNER}/in-days`;
export const PAGE_INNER_IN_DAYS_2 = `${PAGE_INNER}/in-days-2`;
export const PAGE_INNER_WAREHOUSES = `${PAGE_INNER}/warehouses`;
export const PAGE_INNER_SALES = `${PAGE_INNER}/sales`;
export const PAGE_INNER_WEEKS = `${PAGE_INNER}/weeks`;
export const PAGE_INNER_PRODUCT = `${PAGE_INNER}/inner_product`;
export const PAGE_INNER_PRODUCT_ID = `${PAGE_INNER_PRODUCT}/:id/:barcode`;

export const PAGE_API_KEYS = '/api-keys';
export const PAGE_NAVIGATION = '/navigation';
