import productListClient from 'services/productList';

const createListMethod = async ({ name, type, is_default }) => {
  try {
    const { list_id, success } = await productListClient.createList({ name, type, is_default });
    if (success) {
      return list_id;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const renameListMethod = async ({ id, name }) => {
  try {
    await productListClient.renameList({ id, name });
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteListMethod = async (id) => {
  try {
    await productListClient.deleteList(id);
  } catch (error) {
    throw new Error(error.message);
  }
};

const clearListMethod = async (id) => {
  try {
    await productListClient.clearList(id);
  } catch (error) {
    throw new Error(error.message);
  }
};

const addFavouriteMethod = async ({ listId, id, platform_id }) => {
  try {
    await productListClient.addItemToList({ listId, id, platform_id });
  } catch (error) {
    throw new Error(error.message);
  }
};

const removeFavouriteMethod = async ({ listId, id, platform_id }) => {
  try {
    await productListClient.deleteItemFromList({ listId, id, platform_id });
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  addFavouriteMethod,
  clearListMethod,
  createListMethod,
  deleteListMethod,
  removeFavouriteMethod,
  renameListMethod,
};
