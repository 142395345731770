import axios from 'axios';
import { cleanToken, logout, setToken } from 'context/auth.methods';
import { getToken } from 'utils/token';
import authClient from './auth';

class AbstractClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
      xsrfHeaderName: 'X-CSRFTOKEN',
      xsrfCookieName: 'csrftoken',
    });

    this.client.interceptors.request.use(function (config) {
      const token = getToken();
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9rZWtzdW02NjYud2VjaGVjay5kZXZcL2FwaVwvYXV0aFwvbG9naW4iLCJpYXQiOjE2NjQ5NjAxNDksImV4cCI6MTY2NzU1MjE0OSwibmJmIjoxNjY0OTYwMTQ5LCJqdGkiOiIyemI5cjhLUW1QeHhNR2VOIiwic3ViIjoyLCJwcnYiOiIyYjFjMDc5NDJhM2NlYTQ3MDY4ZDRmNTA1ZWY5ZDgzZWYwNTk0NGNhIn0.fyK7q4XBs1YybMFXYaYU1X_SdOrgCOgmULvPC4Odsdo`;

      return config;
    });

    // refetch token
    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (originalRequest?.ignoreGlobalCatch) {
          return Promise.resolve(error);
        }
        // If 401 error and token hasn't been refreshed already
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            // Refresh the token
            const { data } = await authClient.refresh();
            setToken(data);
            const token = getToken();
            // Set the new access token in the Authorization header
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return this.client(originalRequest);
          } catch (refreshError) {
            logout().finally(() => {
              window.location.href = '/';
              cleanToken();
            });
          }
        }

        return Promise.reject(error);
      },
    );
  }
}

export default AbstractClient;
