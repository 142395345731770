import React, { useCallback, useEffect, useRef, useState } from 'react';

import { PAGE_API_KEYS, PAGE_INNER_DELIVERY_TAB, PAGE_INNER_PRICES_TAB } from 'constants';
import {
  COLUMN_HIDE_FILTER_ON_EMPTY_SORTING,
  COLUMNS,
  TABLE_COLUMNS_TEXT_DATES_FILTERED_ON_BACK,
  TABLE_COLUMNS_TEXT_FILTERED_ON_BACK,
  TABLE_EXPORT_BACKEND,
  TABLE_RESTRICTED_ON_BACK,
  TABLE_RESTRICTED_ON_BACK_WITH_FILTER,
  TABLE_SORTED_ON_BACK,
  TABLE_TEXT_FILTERED_ON_BACK,
  TABLE_TYPE,
} from 'constants/table';
import { useAnalyticType } from 'context/analytic.type.context';
import { useAuth } from 'context/auth.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { CSS_CLASS_FOR_NESTED_HEADER_CELL, HEADER_HEIGHT } from 'helpers/generateGridConfig';
import { STICKY_ATTRIBUTE } from 'hooks/useStickyObserver';
import moment from 'moment';
import { getLinks } from '../components/ProductTableNav/links';
import {
  PAGE_BRANDS,
  PAGE_INNER_IN_DAYS_2,
  PAGE_INNER_PRODUCTS,
  PAGE_INNER_PRODUCTS_SETTINGS,
  PAGE_INNER_SALES,
  PAGE_INNER_WAREHOUSES,
  PAGE_INNER_WEEKS,
  PAGE_MAIN,
  PAGE_PRICE_SEGMENTS,
  PAGE_PRODUCTS,
  PAGE_SALES,
  PAGE_SELLERS,
  PAGE_TRENDS,
  PAGE_WAREHOUSES,
} from '../constants';
import { isInnerProductPage, isProductPage } from '../utils/pages';
import { useProduct } from './product.context';

const TABLE_LINKS = [
  {
    link: PAGE_INNER_IN_DAYS_2,
    title: 'По дням',
  },
  {
    link: PAGE_INNER_PRODUCTS,
    title: 'Товары',
  },
  // {
  //   link: PAGE_INNER_IN_DAYS,
  //   title: 'По дням',
  // },
  {
    link: PAGE_INNER_SALES,
    title: 'Заказы. Продажи. Возвраты',
  },
  {
    link: PAGE_INNER_WEEKS,
    title: 'Еженедельные отчеты',
  },
  {
    link: PAGE_INNER_WAREHOUSES,
    title: 'Склады',
  },
  {
    link: PAGE_MAIN,
    title: 'Категории',
  },
  {
    link: PAGE_SELLERS,
    title: 'Продавцы',
  },
  {
    link: PAGE_BRANDS,
    title: 'Бренды',
  },
  {
    link: PAGE_PRODUCTS,
    title: 'Товары',
  },
  {
    link: PAGE_TRENDS,
    title: 'Тренды',
  },
  {
    link: PAGE_WAREHOUSES,
    title: 'Склады',
  },
  {
    link: PAGE_PRICE_SEGMENTS,
    title: 'Ценовая сегментация',
  },
  {
    link: PAGE_SALES,
    title: 'По дням',
  },
  {
    title: 'Расчет поставки',
    link: PAGE_INNER_DELIVERY_TAB,
  },
  {
    title: 'Цены',
    link: PAGE_INNER_PRICES_TAB,
  },
  {
    title: 'Себестоимость',
    link: PAGE_INNER_PRODUCTS_SETTINGS,
  },
  {
    title: 'API-ключи',
    link: PAGE_API_KEYS,
  },
];

const TableContext = React.createContext();
TableContext.displayName = 'Table.Context';

const TableProvider = (props) => {
  let tableRef = useRef();
  const { isInner } = useAnalyticType();

  const {
    filter,
    chips,
    filterText,
    filterRange,
    setSortNestedDefault,
    isFilterApplied,
    isFilterTextApplied,
  } = useFilterReducer();
  const { isAdmin } = useAuth();

  const [gridOptions, setGridOptions] = useState(null);
  const [initialData, setInitialData] = useState();
  const [dataReady, setDataReady] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isNestedIncluded, setIsNestedIncluded] = useState(false);
  const [type, setType] = useState(null);
  const { productData, isOzonPlatform } = useProduct();

  const [loading, setLoading] = useState(true);

  const isFilterEmpty =
    filter &&
    !filter?.category?.length &&
    !filter?.promoCategory?.length &&
    !filter?.subject?.length &&
    !filter?.brand?.length &&
    !filter?.seller?.length;

  const onGridReady = ({ api, columnApi }) => {
    setGridOptions({ api, columnApi });
  };

  const onDataReady = (value) => setDataReady(value);
  const resize = () => {
    const rootWidth = document.getElementsByClassName('ag-root')?.[0]?.offsetWidth;
    if (rootWidth) {
      const scroll = document.getElementsByClassName('ag-body-horizontal-scroll')?.[0];
      if (scroll) {
        scroll.style['maxWidth'] = `${rootWidth}px`;
      }
    }
  };
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const getResultPeriod = () => {
    const { date = [] } = filter;
    return date
      .map((el) => {
        const result = el.split('-').reverse().join('.').split('');
        result.splice(result.length - 3, 2);
        return result.join('');
      })
      .join('-');
  };

  const getProductTab = () => {
    const { id, barcode, articul } = productData;
    const links = getLinks({
      isInner,
      id: isInner ? articul : id,
      barcode,
      computedTip: () => {},
    });
    return links.find((el) => window.location.pathname === el.link)?.title || '';
  };

  const getProductFileName = () => {
    const { articule, articul, seller } = productData;
    const tabName = getProductTab();
    return `WeCheck/Товар-${articule || articul}/${tabName}/${getResultPeriod()}/${
      isOzonPlatform ? 'Ozon' : 'Wildberries'
    }${seller ? `/${seller.name}` : ''}`;
  };

  const getTableTab = () => {
    return (
      TABLE_LINKS.find((el) => el.link === window.location.pathname)?.title ||
      window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
    );
  };

  const getTableFileName = () => {
    const { platform } = filter;
    const { category, inner_category, brand, inner_brand, seller, inner_seller } = chips;
    const resultCategory = category?.[0] || inner_category?.[0];
    const resultBrand = brand?.[0] || inner_brand?.[0];
    const resultSeller = seller?.[0] || inner_seller?.[0];
    return `WeCheck/${getTableTab()}/${getResultPeriod()}/${
      platform !== '2' ? 'Ozon' : 'Wildberries'
    }${
      resultCategory
        ? resultCategory.title
          ? `/${resultCategory.title}`
          : `/categoryID ${resultCategory.key}`
        : ''
    }${
      resultBrand
        ? resultBrand.title
          ? `/${resultBrand.title}`
          : `/brandID ${resultBrand.key}`
        : ''
    }${
      resultSeller
        ? resultSeller.title
          ? `/${resultSeller.title}`
          : `/sellerID ${resultSeller.key}`
        : ''
    }`;
  };

  const getFileName = (extension) => {
    try {
      const resultName =
        isProductPage() || isInnerProductPage() ? getProductFileName() : getTableFileName();
      return `${resultName}.${extension}`;
    } catch (e) {
      return `WeCheck-${moment().format('DD.MM.YY-HH.mm')}.${extension}`;
    }
  };

  const getTotalPages = useCallback(() => {
    return dataReady && gridOptions && gridOptions.api.paginationGetTotalPages();
  }, [gridOptions, dataReady]);

  const switchIsNestedIncluded = () => setIsNestedIncluded((prev) => !prev);

  useEffect(() => {
    setTotalPages(getTotalPages());
  }, [getTotalPages]);

  const exportCsv = React.useCallback(
    () =>
      gridOptions?.api?.exportDataAsCsv({
        fileName: getFileName('csv'),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridOptions, chips, getFileName],
  );

  const exportExcel = React.useCallback(() => {
    if (!gridOptions.api) return;
    const spreadsheets = [];

    spreadsheets.push(
      gridOptions.api.getSheetDataForExcel({
        sheetName: 'WeCheck',
      }),
    );

    gridOptions.api.exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: getFileName('xlsx'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridOptions, chips, getFileName]);

  const exportByType = React.useCallback(
    (type) => {
      switch (type) {
        case 'xlsx':
          exportExcel();
          break;
        case 'csv':
          exportCsv();
          break;
      }
    },
    [exportExcel, exportCsv],
  );

  const isBackendExport = React.useCallback((type) => TABLE_EXPORT_BACKEND.includes(type), []);

  const onFilterChanged = () => {
    if (!gridOptions?.api?.rowModel?.rowsToDisplay?.length) {
      gridOptions.api.showNoRowsOverlay();
    } else {
      gridOptions.api.hideOverlay();
    }

    // expand items
    if (isFilterTextApplied && type === TABLE_TYPE.CATEGORIES) {
      gridOptions.api.forEachNodeAfterFilter((node) => {
        node.expanded = true;
      });
      gridOptions.api.onGroupExpandedOrCollapsed();
    }

    setTotalPages(getTotalPages());
  };

  const updateTotalPages = () => {
    setTotalPages(getTotalPages());
  };

  const isTableNeedHideFilter = (type) =>
    COLUMN_HIDE_FILTER_ON_EMPTY_SORTING.includes(type) && isFilterEmpty && !isAdmin;

  const isTableSortedOnBack = (type) => TABLE_SORTED_ON_BACK.includes(type);

  const isTableRestrictedOnBack = (type) => {
    if (isFilterApplied && !isFilterTextApplied && !isInner) {
      return TABLE_RESTRICTED_ON_BACK_WITH_FILTER.includes(type);
    }
    return TABLE_RESTRICTED_ON_BACK.includes(type);
  };

  const isFilterTextOnBack = ({ type, key }) => {
    if (type === TABLE_TYPE.CATEGORIES && key === COLUMNS.NAME) {
      return false;
    }
    return (
      TABLE_TEXT_FILTERED_ON_BACK.includes(type) &&
      [
        ...TABLE_COLUMNS_TEXT_FILTERED_ON_BACK,
        ...TABLE_COLUMNS_TEXT_DATES_FILTERED_ON_BACK,
      ].includes(key)
    );
  };

  const smoothScrollToTableTop = () => {
    let top = tableRef?.current?.getBoundingClientRect()?.top;

    if (!top) return;

    const stickyElems = document.querySelectorAll(`[${STICKY_ATTRIBUTE}="1"]`);
    let offset = 0;

    for (let i = 0; i < stickyElems.length; i++) {
      offset += stickyElems[i]?.offsetHeight || 0;
    }

    if (offset) {
      top -= offset;
    }

    window.scrollBy({
      top: top - HEADER_HEIGHT + 4,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onPaginationChanged = ({ newPage }) => {
    newPage && smoothScrollToTableTop();
  };

  const setTableType = (type) => setType(type);

  const hideFilter = (colId) => {
    const instance = gridOptions.api.getFilterInstance(colId);

    if (!instance?.hidePopup) return;

    instance.hidePopup();
  };

  const setTableFilterModel = () => {
    if (!gridOptions || (!filterText && !filterRange)) return;

    const model = {};
    if (filterText) {
      Object.keys(filterText).forEach((key) => {
        const isFilteredOnBack = isFilterTextOnBack({ type, key });

        if (!isFilteredOnBack) {
          // для таблицы категорий надо перезадать ключ из-за того,
          // что для колонки с деревом ключ подменяет aggrid
          const textKey = key === COLUMNS.TITLE ? 'ag-Grid-AutoColumn' : key;
          model[textKey] = {
            filterType: 'text',
            type: 'contains',
            value: filterText[key],
          };
        }
      });
    }

    if (filterRange) {
      Object.keys(filterRange).forEach((key) => {
        const isRangedOnBack = isTableRestrictedOnBack({ type, key });

        if (!isRangedOnBack) {
          model[key] = {
            filterType: 'inRange',
            filter: filterRange[key][0],
            filterTo: filterRange[key][1],
          };
        }
      });
    }

    gridOptions.api.setFilterModel(model);
  };

  const isNestedHeaderCell = (column) => {
    const cssClass = column?.colDef?.headerClass;

    if (!cssClass) return false;

    if (typeof cssClass !== 'string') return false;

    return column?.colDef?.headerClass.indexOf(CSS_CLASS_FOR_NESTED_HEADER_CELL) >= 0;
  };

  const onRowGroupOpened = ({ expanded, api }) => {
    if (expanded) {
      api.resetRowHeights();

      setSortNestedDefault();
    }
  };

  const setTableDataLoading = useCallback((loading) => {
    setLoading(loading);
  }, []);

  const value = {
    exportCsv,
    exportExcel,
    exportByType,
    isBackendExport,
    onGridReady,
    dataReady,
    onDataReady,
    onFilterChanged,
    gridOptions,
    totalPages,
    setTotalPages,
    tableRef,
    isNestedIncluded,
    switchIsNestedIncluded,
    isTableNeedHideFilter,
    updateTotalPages,
    smoothScrollToTableTop,
    onPaginationChanged,
    setTableType,
    type,
    hideFilter,
    isTableSortedOnBack,
    isTableRestrictedOnBack,
    isFilterTextOnBack,
    setTableFilterModel,
    isNestedHeaderCell,
    onRowGroupOpened,
    tableDataLoading: loading,
    setTableDataLoading,
    initialData,
    setInitialData,
  };
  return <TableContext.Provider value={value} {...props} />;
};

const useTable = () => React.useContext(TableContext);

export { TableProvider, useTable };
