import TimerIconThin from 'assets/icons/TimerIconThin';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ProductSearchModal from './Modal';

const ProductSearchModalButton = ({ modal }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <TimerIconThin
        width={14}
        height={14}
        className={'text-green cursor-pointer'}
        onClick={() => setShowModal(true)}
      />
      {showModal && <ProductSearchModal {...modal} onHide={() => setShowModal(false)} />}
    </>
  );
};

ProductSearchModalButton.propTypes = {
  button: PropTypes.object,
  modal: PropTypes.object,
};

export default ProductSearchModalButton;
