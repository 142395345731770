import Alert from 'components/Alert';
import { useUserList } from 'context/user.list.context';

const ListAlerts = () => {
  const { alerts, removeAlert } = useUserList();

  return (
    <Alert
      hide={removeAlert}
      items={alerts}
      placement="bottom"
      timeout={1500}
      className="bg-gray-700"
      closeButtonClassName="text-gray-300"
    />
  );
};

export default ListAlerts;
