import React from 'react';
import { useModalFilter } from 'context/filter.modal.context';
import Checkbox from 'components/Checkbox';

export const NewAdvertisingFilter = () => {
  const { state, setIsNew /*setHasAdvertising*/ } = useModalFilter();
  return (
    <div className="flex p-4 border-b border-gray-250 lg:border-0 lg:block lg:pt-5 lg:pl-6 lg:space-y-5">
      <div className="pr-4 border-r border-gray-250 mr-4 lg:pr-0 lg:border-0 lg:mr-0">
        <Checkbox
          label="Только новинки"
          onChange={(e) => setIsNew(e.target.checked)}
          name="is-new"
          checked={!!state.localFilter.isNew}
          className={'whitespace-nowrap'}
        />
      </div>
      {/*<div>*/}
      {/*  <Checkbox*/}
      {/*    label="Товары с рекламой"*/}
      {/*    onChange={(e) => setHasAdvertising(e.target.checked)}*/}
      {/*    name="has-advertising"*/}
      {/*    checked={!!state.localFilter.hasAdvertising}*/}
      {/*    className={'whitespace-nowrap'}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};
