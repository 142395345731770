import EditIcon from 'assets/icons/EditIcon';
import Modal from 'components/Modal';
import { useAuth } from 'context/auth.context';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import baseClient from 'services/baseClient';
import ProfileEditEmail from './EditEmail';
import ProfileEditEmailConfirm from './EditEmailConfirm';
import ProfileEditName from './EditName';

const MODAL_TITLES = {
  email: 'Изменить Email',
  confirm: 'Подтверите код',
  name: 'Изменить Имя',
};

const getRoute = (mode) => {
  switch (mode) {
    case 'confirm':
      return (params) => baseClient.updateProfileConfirm(params);
    default:
      return (params) => baseClient.updateProfile(params);
  }
};

const validateFormValues = (mode) => {
  function isEmpty(str) {
    return !str || str.trim() === '';
  }
  return (values) => {
    let isValid = true;
    let error = undefined;
    switch (mode) {
      case 'confirm': {
        const { code } = values;
        if (isEmpty(code)) {
          isValid = false;
          error = 'Введите Код';
        }

        break;
      }
      case 'email': {
        const { email } = values;
        if (isEmpty(email)) {
          isValid = false;
          error = 'Введите Почту';
        }
        break;
      }
      case 'name': {
        const { name } = values;
        if (isEmpty(name)) {
          isValid = false;
          error = 'Введите Имя';
        }
        break;
      }
    }
    return [isValid, error];
  };
};

const DEFAULT_VALUES = {};
const ProfileEditModal = ({ mode: modeFromProps, values = DEFAULT_VALUES }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState(values);
  const [mode, setMode] = useState(modeFromProps);
  const [openModal, setOpenModal] = useState(false);

  const { updateUser } = useAuth();

  const onChange = (newValues) => {
    setFormValues((values) => ({ ...values, ...newValues }));
  };

  useEffect(() => {
    setFormValues(values);
  }, [values]);
  const MODAL_CONTENT = {
    name: <ProfileEditName onChange={onChange} values={formValues} />,
    email: <ProfileEditEmail onChange={onChange} values={formValues} />,
    confirm: <ProfileEditEmailConfirm onChange={onChange} />,
  };

  const resetToDefaults = () => {
    setMode(modeFromProps);
    setFormValues(values);
    setError(undefined);
  };

  const handleSave = async () => {
    const [isValid, error] = validateFormValues(mode)(formValues);
    if (!isValid) {
      setError(error);
      return false;
    }
    setIsLoading(true);
    const data = await getRoute(mode)(formValues);
    setIsLoading(false);
    const { status } = data;
    if (status === 200) {
      switch (mode) {
        case 'email':
          setMode('confirm');
          break;
        default:
          handleClose();
          updateUser();
          break;
      }
    } else {
      const { message, errors = [] } = data.response?.data || {};
      if (errors) {
        let errorMessages = [];
        for (const field in errors) {
          const messages = errors[field];
          errorMessages = [...errorMessages, ...messages];
        }
        setError(errorMessages);
      } else setError(message);
    }
  };

  const handleClose = () => {
    resetToDefaults();

    setOpenModal(false);
  };

  return (
    <>
      <EditIcon onClick={() => setOpenModal(true)} className={'text-green cursor-pointer'} />
      {openModal && (
        <Modal
          isOpen
          title={MODAL_TITLES[mode]}
          submitButtonText={mode === 'confirm' ? 'Подтвердить' : 'Изменить'}
          submitModal={handleSave}
          closeModal={handleClose}
          width={'auto'}
          submitButtonDisabled={isLoading}
        >
          {MODAL_CONTENT[mode]}

          {error && (
            <div className="flex w-full items-center -mt-4">
              <div className="w-[40%] md:w-40 whitespace-nowrap mr-2" />
              <div className="text-red-600">{renderError(error)}</div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

ProfileEditModal.propTypes = {
  mode: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default ProfileEditModal;

function renderError(error) {
  if (Array.isArray(error)) {
    {
      return error.map((error, index) => <p key={index}>{error}</p>);
    }
  }
  return error;
}
