import PropTypes from 'prop-types';
import { NavLink, useSearchParams } from 'react-router';

import { ReactComponent as Logo } from 'assets/images/logoVertical.svg';
import ButtonBack from 'components/ButtonBack';
import { PAGE_RECOVERY_PASSWORD } from 'constants';

const AuthorizationLayout = ({ children, hideButtonBack = false }) => {
  const [searchParams] = useSearchParams();
  const isDemo = searchParams.get('demo-message');

  const isResetPassword = window.location.pathname.indexOf(PAGE_RECOVERY_PASSWORD) >= 0;
  const showBack = isResetPassword || (!hideButtonBack && isDemo);
  return (
    <main className="wrapper flex-auto flex flex-col">
      {showBack && (
        <div className="self-start mt-4 mb-6">
          <ButtonBack backTo={window.location.pathname === '/reset-password' ? '/' : null} />
        </div>
      )}
      <div
        className={`self-center m-auto flex flex-col items-center max-w-full w-100 ${
          showBack ? '' : 'pt-2'
        }`}
      >
        <NavLink to="/">
          <Logo />
        </NavLink>
        <div className="mt-16 w-full flex flex-col items-center">{children}</div>
      </div>
    </main>
  );
};

AuthorizationLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  hideButtonBack: PropTypes.bool,
};

export default AuthorizationLayout;
