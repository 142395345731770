import PropTypes from 'prop-types';
import { lazy, useEffect } from 'react';

import { COLUMNS, TABLE_INNER_TYPE, TABLE_TYPE } from 'constants/table';
import { generateGridColumns, generateGridHierarchyColumns } from 'helpers/generateGridColumns';
import { generateGridRowData } from 'helpers/generateGridRowData';

import classNames from 'classnames';
import TableFooter from 'components/TableFooter';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useTable } from 'context/table.context';
import useStickyTable from 'hooks/useStickyTable';

const TableGrid = lazy(() => import('components/TableGrid'));

const Table = ({
  data = { pages: [] },
  dataKey,
  dataPagesKey,
  type,
  additionalData = null,
  categoriesData = [],
  autoSize = false,
  autoSizeColumns = null,
  paginationOnBack = false,
  hideSoldColumn = false,
  isSellerCellClickable = true,
  onCellClicked,
  isLoading,
  noRowsText = null,
  onRowSelected,
  typeNested,
  nested,
  hideFilterPanel = false,
  autoSizeNestedColumns,
  nestedCheckbox = false,
  footerExtra,
  footerDescription,
  hidePagination,
  asyncGetDetailRowData,
  showSidebar = true,
  useFooter = true,
  totalPages,
  paginationData,
  getRowHeight = null,
  bordered = false,
  useSticky = true,
}) => {
  const { tableRef, isTableNeedHideFilter, setTableDataLoading } = useTable();
  const isFilterHidden = isTableNeedHideFilter(type);
  const {
    filter: { period },
  } = useFilterReducer();

  useStickyTable(useSticky);

  // иногда данные приходят с разделением на периоды
  // например для таблиц growth темпы роста
  if (data?.[period]) {
    data = data?.[period];
  }

  if (data?.pages) {
    data = data?.pages?.reduce((acc, cur) => {
      let currentData = [];

      // check if we have data in the returned object
      let current = dataPagesKey ? cur[dataPagesKey] : cur;

      // данные должны быть массивом
      if (Array.isArray(current)) {
        currentData = current;
        // иногда данные приходят с разделением на периоды
        // например для таблиц growth темпы роста
      } else if (current?.[period] && Array.isArray(current?.[period])) {
        currentData = current?.[period];
      }
      return [...acc, ...currentData];
    }, []);
  }

  let columnDefs = generateGridColumns({
    type,
    data,
    hideSoldColumn,
    isSellerCellClickable,
    showFilterHiddenTooltip: isFilterHidden,
  });

  const columnDefsNested = generateGridColumns({
    type: typeNested,
    data,
  });
  let transformedData = {};

  if (data && !isLoading) {
    transformedData = generateGridRowData({
      data,
      type,
      additionalData,
      columnDefs,
      categoriesData,
      nested,
    });
  }
  const hierarchyColumn = generateGridHierarchyColumns({
    type,
  });

  const rowData = dataKey
    ? transformedData?.rowData?.[dataKey] || []
    : transformedData?.rowData || [];

  columnDefs = dataKey ? columnDefs?.[dataKey] || [] : columnDefs;

  useEffect(() => {
    setTableDataLoading(isLoading);
  }, [setTableDataLoading, isLoading]);

  // useEffect(() => {
  //   console.log('setInitialData');
  //   // setInitialData(data);
  // });

  return (
    <>
      <div className="flex flex-auto flex-col" ref={tableRef}>
        <div
          id={`myGrid${type}`}
          className={classNames('ag-theme-alpine font-sans flex-auto', { bordered })}
        >
          <TableGrid
            columnDefs={columnDefs}
            rowData={rowData}
            initialData={data}
            pinnedTopRowData={transformedData?.pinnedTopRowData}
            hierarchyColumn={hierarchyColumn}
            isSellerCellClickable={isSellerCellClickable}
            type={type}
            autoSize={autoSize}
            autoSizeColumns={autoSizeColumns}
            autoSizeNestedColumns={autoSizeNestedColumns}
            noRowsText={noRowsText}
            onRowSelected={onRowSelected}
            isLoading={isLoading}
            nested={nested}
            columnDefsNested={columnDefsNested}
            hideFilterPanel={hideFilterPanel}
            showFilterHiddenTooltip={isFilterHidden}
            asyncGetDetailRowData={asyncGetDetailRowData}
            showSidebar={showSidebar}
            getRowHeight={getRowHeight}
            onCellClicked={onCellClicked}
          />
        </div>
      </div>
      {useFooter && (
        <TableFooter
          type={type}
          paginationOnBack={paginationOnBack}
          nestedCheckbox={nestedCheckbox}
          extra={footerExtra}
          description={footerDescription}
          isLoading={isLoading}
          hidePagination={hidePagination}
          totalPages={totalPages}
          paginationData={paginationData}
        />
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      pages: PropTypes.array,
    }),
  ]).isRequired,
  dataKey: PropTypes.string,
  dataPagesKey: PropTypes.string,
  additionalData: PropTypes.object,
  type: PropTypes.oneOf([...Object.values(TABLE_TYPE), ...Object.values(TABLE_INNER_TYPE)])
    .isRequired,
  typeNested: PropTypes.oneOf(Object.values(TABLE_TYPE)),
  categoriesData: PropTypes.array,
  autoSize: PropTypes.bool,
  autoSizeColumns: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COLUMNS))),
  autoSizeNestedColumns: PropTypes.arrayOf(PropTypes.string),
  paginationOnBack: PropTypes.bool,
  hideSoldColumn: PropTypes.bool,
  hideFilterPanel: PropTypes.bool,
  isSellerCellClickable: PropTypes.bool,
  isLoading: PropTypes.bool,
  totalPages: PropTypes.number,
  noRowsText: PropTypes.string,
  onRowSelected: PropTypes.func,
  getRowHeight: PropTypes.func,
  nested: PropTypes.string,
  nestedCheckbox: PropTypes.bool,
  nestedParentColumn: PropTypes.string,
  footerExtra: PropTypes.element,
  footerDescription: PropTypes.element,
  hidePagination: PropTypes.bool,
  asyncGetDetailRowData: PropTypes.func,
  onCellClicked: PropTypes.func,
  showSidebar: PropTypes.bool,
  useFooter: PropTypes.bool,
  bordered: PropTypes.bool,
  useSticky: PropTypes.bool,
  paginationData: PropTypes.any,
};

export default Table;
