import DownIcon from 'assets/icons/DownIcon';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useAuth } from '../../context/auth.context';
import { useNavigation } from '../../context/navigation.context';

const Navigation = () => {
  const { NAVIGATION_BLOCKS } = useNavigation();
  const { isBasic, isAdmin } = useAuth();
  const resultBlocks = isBasic
    ? [NAVIGATION_BLOCKS[0]]
    : isAdmin
      ? NAVIGATION_BLOCKS
      : [
          NAVIGATION_BLOCKS[0],
          NAVIGATION_BLOCKS[1],
          { ...NAVIGATION_BLOCKS[2], items: NAVIGATION_BLOCKS[2].items.slice(0, -1) },
        ];
  return (
    <div className="flex grow bg-gray-50 lg:px-[120px]">
      <div className="flex flex-col items-center w-full">
        <span className="block my-10 font-bold text-[#333D37] text-[32px] leading-[36px]">
          Инструменты WeCheck для увеличения продаж
        </span>
        <div className="flex space-x-3 w-full">
          {resultBlocks
            .filter(({ isPrivate = false }) => isAdmin || !isPrivate)
            .map((card) => (
              <div
                key={card.title}
                className="border border-[#E8E8E8] bg-white rounded-lg w-1/4 py-4 px-6 pb-1 h-fit"
              >
                <div className="flex items-center justify-center w-9 h-9 border border-black/10 rounded-lg mb-4">
                  {card.icon}
                </div>
                <span className="block text-[#213A2B] font-bold text-[18px] leading-[22px]">
                  {card.title}
                </span>
                <div className="mt-4">
                  {card.items
                    .filter(({ isPrivate = false }) => isAdmin || !isPrivate)
                    .map((item) => (
                      <NavigateButton item={item} key={item.name} />
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const NavigateButton = ({ item }) => {
  const [hovered, setIsHovered] = useState(false);
  return (
    <button
      key={item.name}
      className="w-full flex py-3 border-t border-[#F0F0F0] justify-between text-left"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        window.location.pathname = item.link;
      }}
    >
      <span
        className={cn('block font-medium text-[#333D37]', {
          'text-[#333D37]': !hovered,
          'text-[#219653]': hovered,
        })}
      >
        {item.name}
      </span>
      <DownIcon
        width={10}
        height={10}
        className={cn('self-center -rotate-90', {
          'text-[##99A0AE]': !hovered,
          'text-[#219653]': hovered,
        })}
      />
    </button>
  );
};
NavigateButton.propTypes = {
  item: PropTypes.any,
};

export default Navigation;
