import cn from 'classnames';
import PropTypes from 'prop-types';
const FormInput = ({ label, id, name, error, ...props }) => {
  return (
    <>
      <div className="flex w-full items-center mb-5">
        <label htmlFor={id} className="w-[40%] md:w-40 whitespace-nowrap mr-2">
          {label}
        </label>

        <input
          id={id}
          name={name}
          className={cn(
            'w-[60%] md:w-80 appearance-none border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 focus:ring-green',
            { 'border-red-500': error && error !== '' },
          )}
          {...props}
        />
      </div>
      {error && (
        <div className="flex w-full items-center -mt-4">
          <div className="w-[40%] md:w-40 whitespace-nowrap mr-2" />
          <div className="text-red-600">{error}</div>
        </div>
      )}
    </>
  );
};

FormInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.error,
  label: PropTypes.label,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default FormInput;
