import CheckIcon from 'assets/icons/CheckIcon';
import InputWithAddon from 'components/Input/InputWithAddon';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TableCardMonopolizationInput = ({ onChange, value: valueFromProps }) => {
  const [isChanged, setIsChanged] = useState(false);
  const [value, setValue] = useState(valueFromProps);

  const clearPercent = (value) => {
    return parseInt(value, 10);
  };

  const handleChange = (value) => {
    if (value === '') {
      setValue('');
      setIsChanged(false);
      return;
    }
    let v = clearPercent(value);
    if (Number.isNaN(v)) {
      return null;
    }

    if (v < 1) {
      v = 1;
    } else if (v > 100) {
      v = 100;
    }

    setIsChanged(true);
    setValue(v);
  };

  const onSubmit = () => {
    const v = clearPercent(value);
    onChange(v);
    setIsChanged(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <div className="flex items-center ml-1 w-20">
      <InputWithAddon
        name={'monopolization'}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
        inputClassName={'text-right'}
        postfix={'%'}
        addonAfter={
          isChanged && (
            <div className="ml-1 px-1 border-l border-gray-300">
              <CheckIcon className={'text-green cursor-pointer'} onClick={onSubmit} />
            </div>
          )
        }
      />
    </div>
  );
};

TableCardMonopolizationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default TableCardMonopolizationInput;
