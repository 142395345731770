import Tree from 'rc-tree';

import LoadingSpin from 'components/LoadingSpin';
import { useModalFilter } from 'context/filter.modal.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUI } from 'context/ui.context';
import { useSearchBrands } from 'hooks/useSearch';
import ButtonPrimary from '../../ButtonPrimary';
import { ChipWithCount, SearchInput } from '../components';

export const Brands = () => {
  const { query, onChange, value } = useSearchBrands();
  const { refetch, isLoading, isRefetching, data = [] } = query;
  const { chips } = useFilterReducer();
  const {
    addBrand,
    removeBrand,
    state: { touched, localChips, localFilter },
  } = useModalFilter();
  const { isMobile } = useUI();

  const onCheck = (_, data) =>
    addBrand({
      key: data.node.key,
      title: data.node.title,
      titleWithCount: data.node.titleWithCount,
    });
  const onUncheck = (_, data) =>
    removeBrand({
      key: data.node.key,
      title: data.node.title,
    });

  const brandsList =
    typeof data === 'string'
      ? []
      : data
          .filter((item) => !localChips.brand.some((brand) => brand.key === item.key))
          .map((data) => ({
            ...data,
            title: data.title,
            titleWithCount: <ChipWithCount title={data.title} count={data.count_products} />,
          }));

  const selectedTreeProps = touched.brand
    ? {
        treeData: localChips.brand,
        checkedKeys: localFilter.brand,
      }
    : { treeData: chips.brand, checkedKeys: chips.brand.map((item) => item.key) };

  const brandsTreeProps = {
    treeData: brandsList,
    checkedKeys: [],
  };
  return (
    <>
      <div className="flex mb-2">
        <div className="flex grow h-10">
          <SearchInput
            value={value}
            onChange={onChange}
            placeholder="Поиск по брендам"
            className="w-full"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                refetch();
              }
            }}
          />
        </div>
        <ButtonPrimary
          onClick={() => refetch()}
          label={<span className="block mx-auto">Поиск</span>}
          className="text-center items-center ml-2 h-10"
          disabled={!value}
        />
      </div>
      <div className="flex-1">
        {isLoading || isRefetching ? (
          <LoadingSpin isVisible={true} />
        ) : (
          <div className="overflow-y-auto max-h-[400px] lg:h-auto">
            {typeof data === 'string' && data}
            {Array.isArray(data) && data.length !== 0 && (
              <div className="flex justify-between text-gray-300 pl-6 pr-2 pb-1.5 sticky top-0 bg-white z-10">
                <span>Бренды</span>
                <span>Артикулов в наличии</span>
              </div>
            )}
            <Tree
              checkable
              selectable={false}
              onCheck={onUncheck}
              showIcon={false}
              rootClassName={`tree-with_count ${isMobile ? 'tree_mobile' : ''}`}
              titleRender={(data) => data.titleWithCount || data.title}
              {...selectedTreeProps}
            />
            <Tree
              checkable
              selectable={false}
              onCheck={onCheck}
              showIcon={false}
              rootClassName={`tree-with_count ${isMobile ? 'tree_mobile' : ''}`}
              titleRender={(data) => data.titleWithCount || data.title}
              {...brandsTreeProps}
            />
          </div>
        )}
      </div>
    </>
  );
};
