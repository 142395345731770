import Table from 'components/Table';
import { TABLE_TYPE } from 'constants';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

const { default: Modal } = require('components/Modal');

const ProductSearchModal = (props) => {
  const { onHide, title, categoryId } = props || {};
  const { id } = useParams();
  //   console.log(id, 'id');
  const { data, isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_SEARCH_TIMES,
    id,
    extraQueryParams: {
      categoryId,
    },
    enabled: true,
  });

  return (
    <Modal
      isOpen
      title={title}
      closeModal={onHide}
      width={800}
      renderSubmitButton={false}
      cancelButtonText={'Закрыть'}
    >
      <Table
        data={getRows(data) || []}
        type={TABLE_TYPE.PRODUCT_SEARCH_TIMES}
        isLoading={isLoading || isFetching}
        hideFilterPanel={true}
        showSidebar={false}
        hidePagination
        bordered
        useSticky={false}
        useFooter={false}
      />
    </Modal>
  );
};

ProductSearchModal.propTypes = {
  onHide: PropTypes.func,
};

export default ProductSearchModal;

const getRows = (data) => {
  if (!data) {
    return [];
  }
  const dates = [...new Set(data.map((item) => item.date))].reverse();
  const times = [...new Set(data.map((item) => item.time))];

  const rowData = times.map((time) => {
    const listing = {};
    dates.forEach((date) => {
      const entry = data.find((item) => item.date === date && item.time === time);
      listing[date] = entry;
    });
    return { time, listing: listing };
  });
  return rowData;
};
